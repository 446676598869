export interface IApiPaths {
    kunder?: string;
    radgivere?: string;
    kontaktpersoner?: string;
    rettigheter?: string;
    veilederListe?: string;
    veileder?: string;
    veilederSteg?: string;
    veiledereIArbeid?: string;
    kundeimport?: string;
    innloggetbruker?: string;
    digitalsignering?: string;
    digitalsigneringEksternt?: string;
    dokumentoversiktEgetRegnskapsforetak?: string;
    egetRegnskapsforetakRedigering?: string;
    dokumentoversiktEgetRegnskapsforetakDokumenter?: string;
}

declare global {
    interface Window {
        apiPaths: IApiPaths;
        isExternalApp: boolean;
    }
}

export const setApiPaths = (apiPaths: IApiPaths) => {
    window.apiPaths = apiPaths;
};

export default window.apiPaths;
